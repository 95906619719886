import { useCallback, useEffect, useState } from 'react'
import '../../css/imgBox.css'
import '../../css/products.css'
import favOn from '../../imgs/interface/favOn.gif'
import favOff from '../../imgs/interface/favOff.gif'

import config from '../../config'
import defaultImage from "../../imgs/interface/no-image-icon.png";

import { useGlobalContext } from '../../hooks/useGlobalContext'

const ProductDetails = ({ products, isLoading, setIsLoading }) => {
    
    const [LoveIcon, setLoveIcon] = useState(favOff)
    const { favoris, favorisDispatch } = useGlobalContext()

    const [enStock, setEnStock] = useState()

    const [imagesList, setImagesList] = useState([]);
    const [activeImage, setActiveImage] = useState();

    
    const handleClickFav = (e) => {
        e.preventDefault()

        const clickedFav = favoris.filter(produit => produit._id === products._id)

        if (clickedFav.length > 0) {
            favorisDispatch({ type: 'DELETE_FAVORI', payload: products })

        } else {

            favorisDispatch({ type: 'ADD_FAVORI', payload: products })

        }

    }

    useEffect(() => {
        var activeFavory = favoris.filter(produit => produit._id === products._id)
        if (activeFavory.length > 0) {
            setLoveIcon(favOn)
        } else {
            setLoveIcon(favOff)
        }

    }, [products, favoris])


    useEffect(() => {
        if (products.Quantite > 1) {
            var stockLigne = products.Quantite + " de disponibles";
            setEnStock(stockLigne)
        } else {
            var stockLigne = products.Quantite + " de disponible";
            setEnStock(stockLigne)

        }
    }, [products])

    const getImages = async () => {
        const response = await fetch(
            `${config.BASE_URL}/api/images/all/${products._id}`
        );
        const json = await response.json();
        if (response.ok && json.length > 0) {
            const newImageList = json.map(async (image, index) => {
                const awsKey = image.awsKey;
                const signedUrl = await getSignedURL(awsKey);
                const imageObject = { image: signedUrl, order: image.sortOrder };
                if (index === 0) {
                    setActiveImage(imageObject.image);
                }
                return imageObject;
            });
            const resolvedImages = await Promise.all(newImageList);
            setImagesList(resolvedImages);
            setIsLoading(false);
        } else {
            setActiveImage(defaultImage);
            setIsLoading(false);
        }
    };
    
    const getSignedURL = async (awsKey) => {
        const response = await fetch(
          `${config.BASE_URL}/api/generate-signed-url?key=${awsKey}`
        );
        if (response.ok) {
            const data = await response.json();
            return data.signedUrl;
        } else {
          // Handle errors here, e.g., return a default image URL.
          return defaultImage;
        }
    };

  const handleImgClick = useCallback((image) => {
    console.log(image)
    setActiveImage(image);
  }, []);

    useEffect(() => {


        if (products._id) {
            getImages();
        }

        if (products.Quantite > 1) {
            var stockLigne = products.Quantite + " de disponibles";
            setEnStock(stockLigne);
        } else {
            var stockLigne = products.Quantite + " de disponible";
            setEnStock(stockLigne);
        }



    }, [products._id]);


    useEffect(() => {
        setImagesList([]);
    }, []);

    return (
        <>
        {!isLoading && activeImage &&
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 m-0">
                <div className="col p-0">
                    <div className="card imgBox" style={{ marginTop: "20px" }}>
                        <div className="imgBig">
                            <img
                                src={activeImage}
                                className="bigImage"
                                alt=""
                                id="bigImage"
                            />
                        </div>
                        <div className="imgSmall">
                            {imagesList && imagesList.map((image, index) => (
                                <div
                                    key={`image${index}`}
                                    onClick={() => {
                                        handleImgClick(image.image);
                                    }}
                                >
                                    <img
                                        className={activeImage === image.image ? "active" : ""}
                                        src={image.image}
                                        alt={`image-${index}`}
                                        data-index={index}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col p-0">

                    <div className="card cardProductInfo">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-11" style={{ paddingLeft: "0px" }}>
                                    <h3 style={{ paddingBottom: "5px" }}>{products.Name} </h3>
                                </div>
                                <div className="col-1" style={{ paddingRight: "0px", marginTop: "10px" }}>
                                    <div className="favoryBtn" style={{ marginRight: "-5px" }}>
                                        <img src={LoveIcon} alt=""
                                            onClick={(e) => {
                                                handleClickFav(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: "15px", marginTop: "10px" }}>
                                <div className="col" style={{ paddingRight: "0px" }}>
                                    <div className="disponible">{enStock}</div>

                                </div>
                            </div>

                            <div className="row" style={{ marginTop: "20px" }}>
                                <div className="col text-center" style={{ textTransform: "uppercase", letterSpacing: "2px", fontWeight: "bold" }}>
                                    Description
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center descriptionText">
                                    {products.Description !== "" ?
                                        products.Description
                                        : <>N-D</>
                                    }

                                </div>
                            </div>
                            <div className="row">
                                <div className="col ficheDetail">
                                    <div className="row">
                                        <div className="col text-center" style={{ textTransform: "uppercase", letterSpacing: "2px", fontWeight: "bold" }}>
                                            Détails
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col">Numéro : {products.Number}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Année : {products.Year !== "" ?
                                            products.Year
                                            : <>N-D</>
                                        }
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">Marque : {products.Brand !== "" ?
                                            products.Brand
                                            : <>N-D</>
                                        }
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col">Modèle : {products.Model !== "" ?
                                            products.Model
                                            : <>N-D</>
                                        }
                                        </div>

                                    </div>
                                    <div className="row">

                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Largeur : {products.Largeur >> 0 ?
                                                products.Largeur
                                                : <>N-D</>
                                            }
                                            {products.Largeur >> 0 ?
                                                <>cm</>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Profondeur : {products.Profondeur >> 0 ?
                                                products.Profondeur
                                                : <>N-D</>
                                            }
                                            {products.Profondeur >> 0 ?
                                                <>cm</>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Hauteur : {products.Hauteur >> 0 ?
                                                products.Hauteur
                                                : <>N-D</>
                                            }
                                            {products.Hauteur >> 0 ?
                                                <>cm</>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            Diamètre : {products.Diametre >> 0 ?
                                                products.Diametre
                                                : <>N-D</>
                                            }
                                            {products.Diametre >> 0 ?
                                                <>cm</>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "15px" }}>
                                <div className="col">
                                    <span style={{ fontWeight: "bold" }}>Catégories :</span>
                                    <div style={{ display: "inline-flex" }}>
                                        {products.Categories &&
                                            products.Categories.map((categorie, index) => {
                                                return (
                                                    <div
                                                        key={categorie._id}
                                                        style={{ display: "flex", marginLeft: "5px" }}
                                                    >
                                                        {categorie.Name}
                                                        {index !== products.Categories.length - 1 && ","}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "15px" }}>
                                <div className="col">
                                    <span style={{ fontWeight: "bold" }}>Décors :</span>
                                    <div style={{ display: "inline-flex" }}>
                                        {products.Tags &&
                                            products.Tags
                                            .filter(tag => tag.tagType === "Décor")
                                            .map((tag, index) => {
                                                return (
                                                    <div
                                                        key={tag._id}
                                                        style={{ display: "flex", marginLeft: "5px" }}
                                                    >
                                                        {tag.Name}
                                                        {index !== products.Tags.length - 1 && ","}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: "15px" }}>
                                <div className="col">
                                    <span style={{ fontWeight: "bold" }}>Tags :</span>
                                    <div style={{ display: "inline-flex" }}>
                                        {products.Tags &&
                                            products.Tags
                                            .filter(tag => tag.tagType != "Décor")
                                            .map((tag, index) => {
                                                return (
                                                    <div
                                                        key={tag._id}
                                                        style={{ display: "flex", marginLeft: "5px" }}
                                                    >
                                                        {tag.Name}
                                                        {index !== products.Tags.length - 1 && ","}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        
        }
        </>

    )
}
export default ProductDetails
