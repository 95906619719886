import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import './css/sidebar.css'


import Header from "./components/system/header";
import Footer from "./components/system/Footer";

import Home from "./pages/Home";
import Contact from "./pages/Contact"
import Map from "./pages/Map"
import Products from "./pages/Products";
import Favoris from "./pages/favoris";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "./hooks/useGlobalContext";
import Sidebar from "./components/system/sidebar";
import config from "./config";
import Details from "./pages/Products/details";



function App() {

  const [products, setProducts] = useState([]);

  const {categories, categoriesDispatch} = useGlobalContext()
  const [categoryTree, setCategoryTree] = useState()

  const [searching, setSearching] = useState({
    page: 1,
    limit: 24,
    sortBy: "Number",
    orderBy: 1,
    categorie: "Tous",
    decor: ""
  })

  const [searchQuery, setSearchQuery] = useState("");


  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });


  
  const fetchAllProducts = async () => {
    const response = await fetch(`${config.BASE_URL}/api/produits/all`);
    const json = await response.json();
    if (response.ok) {
      setProducts(json);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, []);

  /// Creer la liste des categories
  const fetchCategories = async () => {
    const response = await fetch(`${config.BASE_URL}/api/categories`);
    const json = await response.json();
    categoriesDispatch({ type: "SET_CATEGORIES", payload: json });
  };

  const createCategoryTree = (category, categories) => {
    const subCategories = categories.filter(cat => cat.parentId === category._id);
    if (subCategories.length === 0) {
      return { ...category };
    }
    return {
      ...category,
      children: subCategories.map(subCategory => createCategoryTree(subCategory, categories))
    };
  };

  const createCategories = () => {
    const categoryList = [];
    const rootCategories = categories.filter(cat => cat.parentId == undefined);
    for (let category of rootCategories) {
      categoryList.push(createCategoryTree(category, categories));
    }
    setCategoryTree(categoryList)
  };


  
  useEffect(() => {
    if (!categories) {
      fetchCategories();
    } else {
      createCategories()
    }
  }, [categories]);

  return (
    <div className="App">
      <BrowserRouter>
        <Header 
        searching={searching}
        setSearching={setSearching}
        setState={setState}
        setSearchQuery={setSearchQuery}
        />
        <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />}/>
          <Route path="/Map" element={<Map />}/>
          <Route
              path="/Produits"
              element={<Products 
                  searching={searching}
                  setSearching={setSearching}
                  searchQuery={searchQuery}
                  />}
            />
          <Route
              path="/MesFavoris"
              element={ <Favoris /> }
            />
             <Route
              path="/Produits/:id"
              element={<Details />}
            />
        </Routes>
        <Footer />
        <SlidingPane
            isOpen={state.isPaneOpenLeft}
            from="left"
            width="250px"
            hideHeader={true}
            onRequestClose={() => setState({ isPaneOpenLeft: false })}
            overlayClassName="noPadding"
          >
            <Sidebar products={products} searching={searching} setSearching={setSearching} setState={setState} categoryTree={categoryTree} />
          </SlidingPane>
      </BrowserRouter>
      
      <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          theme="colored"
        />
    </div>
  );
}

export default App;
